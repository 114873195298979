import React from "react";
import { useNavigate } from "react-router-dom";
const emailCandidateProfile = require("../../image/icon/email_candidate_profile_ic.svg");

const AnalyticsEntryCard = ({
  icon,
  title,
  description,
  lastViewDate,
  link,
  buttonText='View Report'
}) => {
  const navigate = useNavigate();

  return (
    <div className="analytics-card">
      <div className="analytics-card__head">
        <div className="analytics-card__head__icon__date">
          <div className="analytics-card__head__icon__card">
            <img
              src={emailCandidateProfile}
              alt={title}
              className="analytics-card__icon"
            />
          </div>
         {lastViewDate && <p className="analytics-card__head__last__view">
            Last viewed: <span className="head__date">{lastViewDate}</span>
          </p>}
        </div>
        <p className="analytics-card__head__title">{title}</p>
      </div>

      <div className="analytics-card__body">
        <p className="analytics-card__body__description">{description}</p>
      </div>
      <div className="analytics-card__footer">
        <button className="btn-set-up-report" onClick={() => link && navigate(link)}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};
export default React.memo(AnalyticsEntryCard);
